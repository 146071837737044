import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../../axiosInstance";
import Cookies from "js-cookie";

const FindOutMore = (props) => {

    return (<div id="more" className=" relative pt-10">
        <div
            className="relative z-0"
        >
            <div className="flex justify-end items-center">
                <img className="h-[200px] object-cover xl:mt-10 my-24 " src="landing/Hills.svg" alt=""/>
            </div>
            <img className="absolute z-0 bottom-0 object-top w-[120vw] overflow-hidden   h-[220px] object-cover "
                 src="landing/Base2.svg" alt=""/>
        </div>

    </div>)
}

export default FindOutMore;