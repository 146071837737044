import AssignmentIcon from "@mui/icons-material/Assignment";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import PlaceIcon from "@mui/icons-material/Place";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

const truncated = (text, n) =>
    text
        ? text
              .replace(/\s+/g, " ")
              .split(/(?=\s)/gi)
              .slice(0, n)
              .join("") + "..."
        : "";

const SkillEventCard = ({ skillEvent }) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                display: "flex",
                border: skillEvent?.private ? `3px solid ${theme.palette.highlight.main}` : "",
            }}
            color="primary"
        >
            <Grid container>
                {skillEvent.image ? (
                    <Grid
                        item
                        xs={4}
                        sx={{
                            backgroundColor: "white",
                            overflow: "hidden",
                            width: "100%",
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                maxHeight: 180,
                                height: "100%",
                                margin: "auto",
                                objectFit: "scale-down",
                            }}
                            image={skillEvent.image}
                            alt="Skill provider logo"
                        />
                    </Grid>
                ) : null}
                <Grid item xs={skillEvent.image ? 8 : 12}>
                    <Box>
                        <CardContent>
                            <Stack spacing={0.5}>
                                <Link
                                    underline="hover"
                                    color="secondary"
                                    variant="h5"
                                    component={RouterLink}
                                    to={`/skills/${skillEvent.id}`}
                                >
                                    {skillEvent.name}
                                </Link>
                                <Typography color="secondary">
                                    {truncated(skillEvent.description, 15)}
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item md={6} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Skills Event Type">
                                                <AssignmentIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">
                                                {skillEvent.type_display}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Skills Event Location">
                                                <PlaceIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">
                                                {skillEvent.city}
                                                {skillEvent.city ? ", " : ""}
                                                {skillEvent.country_display}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Skills Event Date">
                                                <CalendarMonthIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">{skillEvent.date}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Ticket Price">
                                                <ConfirmationNumberIcon color="secondary" />
                                            </Tooltip>
                                            <Typography color="secondary">{skillEvent.price}</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default SkillEventCard;
